@font-face {
  font-family: "PT Sans";
  src: url("../fonts/regular.eot");
  src: url("../fonts/regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/regular.woff") format("woff"),
    url("../fonts/regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "PT Sans";
  src: url("../fonts/italic.eot");
  src: url("../fonts/italic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/italic.woff") format("woff"),
    url("../fonts/italic.ttf") format("truetype");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "PT Sans";
  src: url("../fonts/bold.eot");
  src: url("../fonts/bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/bold.woff") format("woff"),
    url("../fonts/bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}

/* RESET START */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

html {
  font-size: 10px;
}

#page,
.modal-container,
.card #auth {
  font-size: 16px;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* RESET END */

/* KEYFRAMES START */

@keyframes bkg_moveR {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
}

/* KEYFRAMES END */

/* PRESET START */

img {
  vertical-align: top;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #888;
  text-overflow: ellipsis;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px transparent inset; /* Change the color to your own background color */
}

.clear_fix {
  display: block;
}

.clear_fix::after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.clear {
  float: none;
  clear: both;
}

.fl_l,
.fl_c {
  float: left;
}

.fl_r {
  float: right;
}

a {
  color: #0084c6;
  text-decoration: none;
}

.nosel {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.small_dropdown {
  font-weight: normal;
  padding: 3px 5px;
  position: relative;
  top: -3px;
  background: #e4f1f7;
  text-transform: none;
  color: #0090db;
  cursor: pointer;
  border-radius: 2px;
}
/* PRESET END */

/* ] START */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
}
#modal_workspace_reauth.modal-container,
#modal_msg_tab_lock_page.modal-container {
  z-index: 101;
  background: #fff;
}
.modal-overlay {
  background: #000;
}

.modal-container {
  margin: 0;
  border: 0;
  border-collapse: collapse;
  background: rgba(0,0,0,.6);
  text-align: center;
  overflow: auto;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: inline-block;
  vertical-align: middle;
  z-index: 112;
  white-space: nowrap;
}
.modal-container::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 100%;
}

*:first-child + html .modal-container {
  height: 100%;
}

.modal-container_i {
  margin: 0 auto;
  display: inline-block;
  vertical-align: middle;
  z-index: 113;
  text-align: left;
  white-space: normal;
}

.modal-container_i2 {
  margin: 0;
  border: 0;
  vertical-align: middle;
}

.modal-error {
  padding: 20px;
  border-radius: 10px;
  background: #000;
  color: #fff;
}

.modal-loading {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  background: #000 url("../img/loading.gif") no-repeat 50% 50%;
}

.wsModal {
  background: #fff;
  border-radius: 3px;
  position: relative;
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.7);
}

#settings_items_editor-react-root { 
  display: flex; 
  align-items: center;
  min-height: 682px;
}

#reactRoot_UsersMultiProfileModal,
#reactRoot_UsersProfileModal,
#reactRoot_SkillGroupModal,
#reactRoot_incidentToSKUFModal,
#reactRoot_classifierModal {
  min-height: 500px;
  display: flex;
  align-items: center;
}

.wsModal_close {
  position: absolute;
  top: -15px;
  right: -15px;
  height: 24px;
  width: 24px;
  border: 3px #42abe1 solid;
  background: #42abe1;
  border-radius: 10px;
  z-index: 9999;
  cursor: pointer;
}

.wsModal_close:hover {
  background: #fff;
}

.wsModal_close:before,
.wsModal_close:after {
  display: block;
  width: 16px;
  height: 3px;
  background: #fff;
  transform: rotate(45deg);
  border-radius: 1px;
  position: absolute;
  top: 11px;
  left: 4px;
  content: "";
}

.wsModal_close:after {
  transform: rotate(-45deg);
}

.wsModal_close:hover:before,
.wsModal_close:hover:after {
  background: #42abe1;
}

.wsModal_title {
  font-size: 1.125em;
  font-weight: bold;
  line-height: 115%;
  text-align: left;
  padding: 25px 0px 20px;
  color: #2a323f;
  margin: 0px 25px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  transition: 0.1s filter;
  -webkit-transition: 0.1s -webkit-filter;
  -moz-transition: 0.1s -moz-filter;
  -ms-transition: 0.1s -ms-filter;
  -o-transition: 0.1s -o-filter;
}

.wsModal_title .tabs {
  margin: 10px 0 -20px;
}

.wsModal_title .tabs.center {
  text-align: center;
}

.wsModal_title .tabs li {
  float: left;
  position: relative;
  color: #828b99;
  padding: 6px 8px 5px 11px;
  cursor: pointer;
}

.wsModal_title .tabs.center li {
  float: none;
  display: inline-block;
}

.wsModal_title .tabs li:hover {
  color: #0090db;
}

.wsModal_title .tabs li.sel {
  color: #0090db;
  background: #f6f6f6;
}
.wsModal_groupTitle {
  font-size: 1em;
  font-weight: bold;
  line-height: 130%;
  padding: 5px 0px;
  color: #2a323f;
  margin: 20px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.wsModal_groupTitle_wrap{
  margin-top: -20px;
}

.wsModal_body {
  padding: 20px 25px 25px 25px;
  line-height: 125%;
}

/* MODAL END */

.wsModule_loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 100px;
  background: #fff;
  z-index: 1000;
}

/* INFORMER START */

@keyframes informer {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0.9, 0.9);
    filter: blur(1px);
  }

  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
    filter: blur(0px);
  }
}

#ws_informer {
  display: none;
  cursor: default;
  padding: 20px 25px;
  font-weight: bold;
  font-size: 1.125em;
  color: #fff;
  position: fixed;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.7);
  text-shadow: 0 1px 0 #111;
}

#ws_informer.show {
  display: block;
  -webkit-animation-name: informer;
  -moz-animation-name: informer;
  -ms-animation-name: informer;
  -o-animation-name: informer;
  animation-name: informer;
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  -o-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

/* INFORMER END */

/* TOOLTIP START */
#tooltip {
  z-index: 9998;
  position: absolute;
  min-width: 10px;
  min-height: 10px;
  background: rgba(0, 0, 0, 0.7);
  padding: 5px 6px;
  line-height: 110%;
  color: #fff;
  border-radius: 3px;
  font-size: 0.8125em;
  text-shadow: 0 1px 0 #111;
}

#tooltip.right_top,
#tooltip.right_middle,
#tooltip.right_bottom,
#tooltip.left_top,
#tooltip.left_middle,
#tooltip.left_bottom,
#tooltip.top_left,
#tooltip.top_middle,
#tooltip.top_right,
#tooltip.bottom_left,
#tooltip.bottom_middle,
#tooltip.bottom_right {
  -webkit-animation-name: puffIn;
  -moz-animation-name: puffIn;
  -ms-animation-name: puffIn;
  -o-animation-name: puffIn;
  animation-name: puffIn;
  -webkit-animation-duration: 0.2s;
  -moz-animation-duration: 0.2s;
  -o-animation-duration: 0.2s;
  animation-duration: 0.2s;
}

#tooltip:before {
  position: absolute;
  border-style: solid;
  content: "";
  opacity: 0.7;
}

#tooltip.right_top:before,
#tooltip.right_middle:before,
#tooltip.right_bottom:before {
  left: -5px;
  border-width: 5px 5px 5px 0;
  border-color: transparent #000 transparent transparent;
}

#tooltip.left_top:before,
#tooltip.left_middle:before,
#tooltip.left_bottom:before {
  right: -5px;
  border-width: 5px 0 5px 5px;
  border-color: transparent transparent transparent #000;
}

#tooltip.left_top:before,
#tooltip.right_top:before {
  top: 5px;
}

#tooltip.left_middle:before,
#tooltip.right_middle:before {
  top: 50%;
  margin-top: -5px;
}

#tooltip.left_bottom:before,
#tooltip.right_bottom:before {
  bottom: 5px;
}

#tooltip.top_left:before,
#tooltip.top_center:before,
#tooltip.top_right:before {
  bottom: -5px;
  border-width: 5px 5px 0 5px;
  border-color: #000 transparent transparent transparent;
}

#tooltip.bottom_left:before,
#tooltip.bottom_center:before,
#tooltip.bottom_right:before {
  top: -5px;
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #000 transparent;
}

#tooltip.top_left:before,
#tooltip.bottom_left:before {
  left: 5px;
}

#tooltip.postsItem:before {
  left: 13px;
}

#tooltip.top_center:before,
#tooltip.bottom_center:before {
  left: 50%;
  margin-left: -5px;
}

#tooltip.top_right:before,
#tooltip.bottom_right:before {
  right: 5px;
}

/* TOOLTIP END */

body {
  min-width: 1010px;
  min-height: 100%;
  -webkit-font-smoothing: subpixel-antialiased;
  font: normal 100%/1.5 "PT Sans", Arial, sans-serif, Candara, Segoe, "Segoe UI",
    Optima;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#page {
  position: relative;
}

/* MAIN DASHBOARD START */

#workspace_dashboard_menu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background: #2a323f;
  width: 100px;
  z-index: 100;
  padding: 10px 0;
}

#workspace_dashboard_menu .logo {
  display: block;
  text-align: center;
  padding: 10px;
  -webkit-transition: opacity 0.1s, background 0.1s;
  -moz-transition: opacity 0.1s, background 0.1s;
  -ms-transition: opacity 0.1s, background 0.1s;
  -o-transition: opacity 0.1s, background 0.1s;
  transition: opacity 0.1s, background 0.1s;
  cursor: default;
}

#workspace_dashboard_menu .logo:before {
  display: inline-block;
  background-size: cover;
  vertical-align: top;
  content: "";
  height: 50px;
}
#workspace_dashboard_menu .logo:before {
  background: transparent url("../img/logo_mini.png") center center no-repeat;
  width: 100%;
  background-size: contain;
}

#workspace_dashboard_menu .space {
  margin: 10px;
  border-top: 1px rgba(255, 255, 255, 0.15) solid;
}

#workspace_dashboard_menu .item {
  cursor: pointer;
  position: relative;
  display: block;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.6);
  line-height: 115%;
  text-align: center;
  padding: 15px 10px 15px 7px;
  text-align: center;
  font-size: 0.875em;
  -webkit-transition: color 0.1s, background 0.1s;
  -moz-transition: color 0.1s, background 0.1s;
  -ms-transition: color 0.1s, background 0.1s;
  -o-transition: color 0.1s, background 0.1s;
  transition: color 0.1s, background 0.1s;
  border-left: 3px transparent solid;
}

#workspace_dashboard_menu .item:hover,
#workspace_dashboard_menu .item.sel {
  color: rgba(255, 255, 255, 1);
  background: rgba(0, 0, 0, 0.1);
}

#workspace_dashboard_menu .item.sel {
  background: rgba(255, 255, 255, 0.1);
  padding-left: 7px;
  border-color: #176ffc;
}

#workspace_dashboard_menu .item span.notification {
  position: absolute;
  top: 10px;
  right: 10px;
  line-height: 100%;
  padding: 4px 4px 3px;
  border-radius: 11px;
  background: #f00;
  font-size: 0.7857142857em;
  font-weight: bold;
  min-width: 10px;
  text-align: center;
  border: 2px #2a323f solid;
  color: #fff !important;
  -webkit-transition: border-color 0.1s;
  -moz-transition: border-color 0.1s;
  -ms-transition: border-color 0.1s;
  -o-transition: border-color 0.1s;
  transition: border-color 0.1s;
}

#workspace_dashboard_menu .item.sel span.notification:hover {
  border-color: #262d39;
}

#workspace_dashboard_menu .item.sel span.notification {
  border-color: #3f4652;
}

#workspace_dashboard_menu .item:before {
  display: block;
  width: 25px;
  height: 25px;
  margin: 0 auto 7px;
  background-size: cover !important;
  background: transparent url("../img/msg_menu.png") no-repeat;
  opacity: 0.6;
  -webkit-transition: opacity 0.1s;
  -moz-transition: opacity 0.1s;
  -ms-transition: opacity 0.1s;
  -o-transition: opacity 0.1s;
  transition: opacity 0.1s;
}

#workspace_dashboard_menu .item.chat:before,
#workspace_dashboard_menu .item.stats:before,
#workspace_dashboard_menu .item.settings:before {
  content: "";
  background-position: 0 0 no-repeat;
}

#workspace_dashboard_menu .item.stats:before {
  background-position: 0 -25px;
}

#workspace_dashboard_menu .item.settings:before {
  background-position: 0 -50px;
}

#workspace_dashboard_menu .item:hover:before,
#workspace_dashboard_menu .item.sel:before {
  opacity: 1;
}

#workspace_dashboard_menu .item.digest {
  position: relative;
}

#workspace_dashboard_menu .item.digest:before {
  background-position: 0 0;
  content: "";
}

#workspace_dashboard_menu .item.digest .day {
  position: absolute;
  width: 25px;
  top: 25px;
  left: 50%;
  margin-left: -13px;
  line-height: 100%;
  color: #2a323f;
  font-weight: bold;
  font-size: 0.9285714286em;
}

#workspace_dashboard_menu .item.digest:hover .day {
  color: #262d39;
}

#workspace_dashboard_menu .item.digest.sel .day {
  color: #1e232c;
}

#workspace_dashboard_menu .item.projects:before {
  background-position: 0 -25px;
  content: "";
}

#workspace_dashboard_menu .item.employees:before {
  background-position: 0 -50px;
  content: "";
}

#workspace_dashboard_menu .item.profile {
  padding: 10px 10px 10px 7px;
}

#workspace_dashboard_menu .item.profile img {
  display: block;
  border-radius: 50%;
  vertical-align: top;
  height: 50px;
  width: 50px;
  opacity: 0.6;
  margin: 0 auto 7px;
  -webkit-transition: opacity 0.1s;
  -moz-transition: opacity 0.1s;
  -ms-transition: opacity 0.1s;
  -o-transition: opacity 0.1s;
  transition: opacity 0.1s;
}

#workspace_dashboard_menu .item.profile:hover img,
#workspace_dashboard_menu .item.profile.sel img {
  opacity: 1;
}

/* MAIN DASHBOARD END */

#workspace_module {
  padding: 0 0 0 350px;
}

body.cool {
  background: #000;
  overflow-y: hidden;
}

#workspace_dashboard_overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
  z-index: 10;
}

body.cool #workspace_dashboard_overlay {
  opacity: 0.7;
  visibility: visible;
}

body.cool #workspace_module {
  -webkit-transform: translate3d(0, 0, -100px);
  -moz-transform: translate3d(0, 0, -100px);
  -ms-transform: translate3d(0, 0, -100px);
  -o-transform: translate3d(0, 0, -100px);
  transform: translate3d(0, 0, -100px);
  position: relative;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: scroll;
  background: #fff;
}

#workspace_module a {
  color: #0090db;
}

#footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

#footer a {
  font-size: 1em;
  line-height: 100%;
  color: rgba(255, 255, 255, 0.5);
  padding: 10px 15px;
}

#footer a:hover {
  color: rgba(255, 255, 255, 0.7);
}

#footer a span {
  display: block;
  padding-top: 5px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.8);
}

#footer a:hover span {
  color: rgba(255, 255, 255, 1);
}

#footer .support {
  text-align: right;
}

.workspace_profile {
  width: 500px;
  height: 250px;
  background: #fff;
  border-radius: 2px;
  position: relative;
  overflow: hidden;
}

.workspace_profile .background {
  position: absolute;
  top: -25px;
  right: -25px;
  bottom: -25px;
  left: -25px;
  background: transparent 50% 50% no-repeat;
  background-size: cover;
  -webkit-filter: blur(15px);
  -moz-filter: blur(15px);
  -o-filter: blur(15px);
  -ms-filter: blur(15px);
  filter: blur(15px);
  z-index: 1;
  border-radius: 2px;
}

.workspace_profile .content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.workspace_profile .content img {
  display: block;
  width: 100px;
  height: 100px;
  vertical-align: top;
  border-radius: 50%;
  margin: 25px auto 0;
  background: #fff;
  padding: 2px;
}

.workspace_profile .content .username {
  padding: 10px 70px 0;
  color: #fff;
  text-align: center;
  font-weight: bold;
  line-height: 115%;
}

.workspace_profile .content .userpost {
  padding: 5px 80px 0;
  color: #bebebe;
  text-align: center;
  line-height: 115%;
  font-size: 0.875em;
  text-transform: lowercase;
}
#workspace_module {
  padding: 0 0 0 100px;
}

#workspace_module.load {
  position: relative;
}

#workspace_module.load:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background: #fff;
  content: "";
}

#workspace_dashboard_menu .item.profile {
  border-left: 3px transparent solid;
  background: transparent;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.6);
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-transition: color 0.1s, background 0.1s;
  -moz-transition: color 0.1s, background 0.1s;
  -ms-transition: color 0.1s, background 0.1s;
  -o-transition: color 0.1s, background 0.1s;
  transition: color 0.1s, background 0.1s;
}

#workspace_dashboard_menu .item.profile:hover {
  color: rgba(255, 255, 255, 1);
  background: rgba(0, 0, 0, 0.1);
}

#workspace_dashboard_menu .item.profile img {
  opacity: 1;
  background: #cee6f2;
}

#workspace_dashboard_menu .version {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  font-size: 0.875em;
  padding: 10px;
  line-height: 120%;
  color: #fff;
}

/* REAUTH START */

#workspace_reauth {
  max-width: 500px;
}

#workspace_reauth .title {
  font-size: 1.3125em;
  line-height: 115%;
  text-align: center;
  padding-top: 170px;
  background: transparent url("../img/workspace_reauth.png") no-repeat 50% 0;
}

#workspace_reauth .title span {
  display: block;
  font-size: 0.7619047619em;
  margin-top: 20px;
  color: #777;
}

#workspace_reauth button {
  margin: 20px 0 0;
}

/* REAUTH END */

/* PAGE_ERROR START */

#workspace_page_error {
  position: relative;
  height: 100%;
  width: 100%;
  font-family: "PT Sans";
}

#workspace_page_error.permission_error {
  position: inherit;
}

#workspace_page_error .workspace_page_error_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#workspace_page_error .title {
  font-size: 1.3125em;
  line-height: 115%;
  text-align: center;
  padding-top: 170px;
  background: transparent url("../img/msg_tab_error.png") no-repeat 50% 0;
}

#workspace_page_error .title.role_title {
  background: transparent url("../img/workspace_reauth.png") no-repeat 50% 0;
}

#workspace_page_error .title span {
  display: block;
  font-size: 0.7619047619em;
  margin-top: 20px;
  color: #777;
}

#workspace_page_error .wsForm_button {
  margin: 20px 0 0;
  font: normal 1em "PT Sans", Arial, sans-serif, Candara, Segoe, "Segoe UI",
    Optima;
  font-size: 0.875em;
  line-height: 100%;
  font-weight: bold;
  background: transparent;
  border: 2px #00a1f5 solid;
  color: #0090db;
  padding: 10px 15px 10px;
  margin: 20px 20px 0;
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  text-transform: uppercase;
  position: relative;
}

#workspace_page_error .wsForm_button:hover {
  border-color: #0090db;
  background-color: #0090db;
  color: #fff;
}

/* PAGE_ERROR END */

/* OFFLINE MODAL START */
#workspace_offline {
  width: 400px;
}
/* OFFLINE MODAL START */
.rate_wrap {
  overflow: hidden;
}
.clear_rate {
  margin-top: 10px;
}

#msg-module .stats_group_queue {
  visibility: hidden;
  opacity: 0;
  cursor: default;
  position: absolute;
  top: 50%;
  left: -10px;
  margin-top: -30px;
  transition: opacity 150ms ease-in-out;
}

#msg-module .stats_agent_load {
  visibility: hidden;
  opacity: 0;
  cursor: default;
  position: absolute;
  top: 50%;
  left: -10px;
  margin-top: -30px;
  transition: visibility 150ms ease-in-out, opacity 150ms ease-in-out;
}

.custom_input {
  display: block;
  border-radius: 2px;
  background: #cee6f2;
  font: normal 100%/1.5 "PT Sans", Arial, sans-serif, Candara, Segoe, "Segoe UI",
    Optima;
  outline: none;
  padding: 10px 8px;
  width: 100%;
  color: #2b2b2b;
  border: 2px #cee6f2 solid;
  resize: vertical;
}
.custom_input.additional_date_field,
.chat_profile_content .custom_input {
  box-sizing: border-box;
}

.single_date_picker {
  position: relative;
}

.single_date_picker > span {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: transparent url(../img/msg_settings.png) 1px -95px no-repeat;
  display: none;
  opacity: 0.5;
  z-index: 2;
}

.single_date_picker:hover > span {
  display: inline-block;
}

.single_date_picker > span:hover {
  opacity: 1;
}

.single_date_picker::after {
  position: absolute;
  content: "";
  cursor: pointer;
  width: 18px;
  height: 18px;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent url(../img/workspace_icons.png) 0 -18px no-repeat;
}

.single_date_picker input {
  padding-left: 33px;
}

.single_date_picker input::placeholder {
  color: #73aecc;
}

.additional_date_field {
  cursor: pointer;
}

.react-viewer-transition {
  transition: none !important;
  -webkit-transition: none !important;
  -o-transition: none !important;
}
